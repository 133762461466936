<template>
  <div>
    <div class="text-center p-2 my-10 mt-8">
      <img class="mx-auto" src="@/assets/logo.png" />
    </div>
    <div class="my-10">
      <img
        class="mx-auto w-2/3 sm:w-2/6"
        src="@/assets/screen-upload-sucesso.png"
      />
    </div>
    <div class="mt-10 w-5/6 sm:w-3/6 mx-auto">
      <div class="text-current text-2xl font-normal">
        Oba, estamos quase lá!
      </div>
      <div class="text-gray-500 opacity-80 font-light text-sm px-0 py-3">
        Suas fotos foram enviadas para o totem com sucesso, continue o processo
        no totem, você já pode fechar essa pagina.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    localStorage.removeItem("compra_id");
    localStorage.removeItem("ig-code");
    localStorage.removeItem("ig-token");
    localStorage.removeItem("ig-userId");
  },
};
</script>
